import getAccessToken from "./accessTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../Config";

const methodService = {
    getMethods: async function(getAccessTokenSilently, getAccessTokenWithPopup) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/methods/`;

            const response = await fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    get: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/methods/`;

            const response = await fetch(url + id , {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    insert: async function(getAccessTokenSilently, getAccessTokenWithPopup, method) {
        try {
            method.id = uuidv4();
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/methods/`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(method)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    update: async function(getAccessTokenSilently, getAccessTokenWithPopup, method) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/methods/`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(method)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    delete: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/methods/`;

            const response = await fetch(url + id, {
                method: 'DELETE',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    }
}

export default methodService;