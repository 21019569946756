import { useEffect , useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Weight.scss';
import { useAuth0 } from "@auth0/auth0-react";
import answerMediaWeightService from '../../services/anwserMediaWeight';
import categoryService from '../../services/category';
import mediaService from '../../services/media';
import CategoryDetails from '../Category/CategoryDetails';
import MediaDetails from './MediaDetails';
import MediaCategoryItem from '../Category/MediaCategoryItem';
import answerService from '../../services/answer';

const Weight = () => {
    const navigate = useNavigate();
    const { answerId } = useParams();
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [categories, setCategories] = useState([]);
    const [medias, setMedias] = useState([]);
    const [answerMediaWeights, setAnswerMediaWeights] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [isCategoryEditActive, setIsCategoryEditActive] = useState(false);
    const [isDeleteConfirmCategoryActive, setIsDeleteConfirmCategoryActive] = useState(false);
    const [isMediaEditActive, setIsMediaEditActive] = useState(false);
    const [isDeleteConfirmMediaActive, setIsDeleteConfirmMediaActive] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [categoryToDelete, setCategoryToDelete] = useState({});
    const [selectedMedia, setSelectedMedia] = useState({});
    const [mediaToDelete, setMediaToDelete] = useState({});

    const onSubmitCategory = async (category) => {
        let newCategories = [];
        if(category.order == '')
        {
          category.order = null;
        }
        if(!category.id) {
            let newCategory = (await categoryService.insert(getAccessTokenSilently, getAccessTokenWithPopup, category)).data;
            newCategories = [...categories, newCategory];
        }
        else {
          await categoryService.update(getAccessTokenSilently, getAccessTokenWithPopup, category);
          newCategories = categories.map(q => {
            return (q.id === category.id)? category : q;
          });
        }
        newCategories = newCategories.sort((a,b) => a.order - b.order);
        setCategories(newCategories);
        setIsCategoryEditActive(false);
    }

    const onCancelCategoryEdit = function() {
      setIsCategoryEditActive(false);
    }

    function debounce(func, timeout = 500){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }

    const changeWeight = async (weight, answerMediaWeight) => {
      if(answerMediaWeight.weight == '')
      {
        answerMediaWeight.weight = null;
        if(answerMediaWeight.id) {
          await answerMediaWeightService.delete(getAccessTokenSilently, getAccessTokenWithPopup, answerMediaWeight.id);
        }
      }
      else {
        if(!answerMediaWeight.id)
        {
          let newAnswerMediaWeight = await answerMediaWeightService.insert(getAccessTokenSilently, getAccessTokenWithPopup, answerMediaWeight);
          answerMediaWeight.id = newAnswerMediaWeight.data.id;
        }
        else {
          await answerMediaWeightService.update(getAccessTokenSilently, getAccessTokenWithPopup, answerMediaWeight);
        }
      }
      setMedias([...medias]);
    }

    const onChangeWeight = async (evt, answerMediaWeight) => {
      answerMediaWeight.weight = evt.target.value;
      setMedias([...medias]);
      debounce(async () => changeWeight(answerMediaWeight.weight, answerMediaWeight) )();
    }
    

    const onSubmitMedia = async (media) => {
      let newMedias = [];
      if(media.order == '')
        {
          media.order = null;
        }
      if(!media.id) {
          let newMedia = (await mediaService.insert(getAccessTokenSilently, getAccessTokenWithPopup, media)).data;
          newMedia.answerMediaWeight = {mediaId: newMedia.id, answerId: answerId, weight:''};
          newMedias = [...medias, newMedia];
      }
      else {
        await mediaService.update(getAccessTokenSilently, getAccessTokenWithPopup, media);
        newMedias = medias.map(q => {
          return (q.id === media.id)? media : q;
        });
      }
      newMedias = newMedias.sort((a,b) => {
        if (a.order === b.order){
          return a.text < b.text ? -1 : 1
        } else {
          return a.order < b.order ? -1 : 1
        }
      });
      setMedias(newMedias);
      setIsMediaEditActive(false);
    }

    const onCancelMediaEdit = function() {
      setIsMediaEditActive(false);
    }

    const onDeleteButtonCategoryClicked = async () => {
      await categoryService.delete(getAccessTokenSilently, getAccessTokenWithPopup, categoryToDelete.id);
      let newCategories = categories.filter((q) => q.id !== categoryToDelete.id);
      setCategories(newCategories);
      setIsDeleteConfirmCategoryActive(false);
      
    }

    const onDeleteButtonMediaClicked = async () => {
      await mediaService.delete(getAccessTokenSilently, getAccessTokenWithPopup, mediaToDelete.id);
      let newMedias = medias.filter((q) => q.id !== mediaToDelete.id);
      setMedias(newMedias);
      setIsDeleteConfirmMediaActive(false);
      
    }

    useEffect(() => {
      (async () => {
        setAnswer(await answerService.get(getAccessTokenSilently, getAccessTokenWithPopup, answerId));
        setCategories(await categoryService.getMediaCategories(getAccessTokenSilently, getAccessTokenWithPopup));
        let fetchedMedias = await mediaService.getMedias(getAccessTokenSilently, getAccessTokenWithPopup);
        let fetchedAnswerMediaWeights = await answerService.getAllAnswerMediaWeightsForAnswer(getAccessTokenSilently, getAccessTokenWithPopup, answerId);
   
        fetchedMedias.map((media, index) => {
          media.answerMediaWeight = {mediaId: media.id, answerId: answerId};
          fetchedAnswerMediaWeights.map((answerMediaWeight, index) => {
            if(media.id === answerMediaWeight.mediaId) {
              media.answerMediaWeight = answerMediaWeight;
            }
          });
        });
        setMedias(fetchedMedias);
        setAnswerMediaWeights(fetchedAnswerMediaWeights);
      })();
      
    }, []);
    return <div>
    <h1 className="u-margin-top-xl"><a href="#" onClick={() => {navigate('/question/' + answer.questionId)}}><i className="fa-solid fa-arrow-left"></i></a>&nbsp;&nbsp;Antwoord: {answer.description}</h1>
    <h2 className="u-margin-top-xl">Media</h2>
    <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsCategoryEditActive(true); setSelectedCategory({type: 'media'});}}>
      <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
      Nieuwe categorie
    </button>
    <br/><br/><br/><br/>

    <ul className="a-list a-list--lined">

      {categories.map((category, index) => (  
                      <MediaCategoryItem key={index}
                      {...{categories}} 
                      {...{medias}} 
                      {...{category}} 
                      {...{setIsMediaEditActive}} 
                      {...{setSelectedMedia}} 
                      {...{setIsCategoryEditActive}} 
                      {...{setSelectedCategory}} 
                      {...{setIsDeleteConfirmCategoryActive}} 
                      {...{setCategoryToDelete}} 
                      {...{setIsDeleteConfirmMediaActive}} 
                      {...{setMediaToDelete}} 
                      {...{onChangeWeight}} 
                      ></MediaCategoryItem>
              ))}  
   
   </ul><br/><br/><br/><br/><br/>
                    
   {isDeleteConfirmCategoryActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze categorie wil verwijderen? </p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonCategoryClicked}>Ja, ik wil ze verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isCategoryEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isCategoryEditActive && 
      <CategoryDetails onSubmit={onSubmitCategory} onCancel={onCancelCategoryEdit} {...{selectedCategory}}/>
    }
    </div>
    <div className={isCategoryEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsCategoryEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
    

    {isDeleteConfirmMediaActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmMediaActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je dit kenmerk wil verwijderen? Denk eraan dat alle gewichten die hiermee verbonden zijn zullen verdwijnen!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonMediaClicked}>Ja, ik wil het verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmMediaActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isMediaEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isMediaEditActive && 
      <MediaDetails onSubmit={onSubmitMedia} onCancel={onCancelMediaEdit} {...{selectedMedia}}/>
    }
    </div>
    <div className={isMediaEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsMediaEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Weight;

