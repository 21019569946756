import { useEffect , useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Weight.scss';
import { useAuth0 } from "@auth0/auth0-react";
import answerMethodWeightService from '../../services/anwserMethodWeight';
import categoryService from '../../services/category';
import methodService from '../../services/method';
import CategoryDetails from '../Category/CategoryDetails';
import MethodDetails from './MethodDetails';
import MethodCategoryItem from '../Category/MethodCategoryItem';
import answerService from '../../services/answer';

const Weight = () => {
    const navigate = useNavigate();
    const { answerId } = useParams();
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [categories, setCategories] = useState([]);
    const [methods, setMethods] = useState([]);
    const [answerMethodWeights, setAnswerMethodWeights] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [isCategoryEditActive, setIsCategoryEditActive] = useState(false);
    const [isDeleteConfirmCategoryActive, setIsDeleteConfirmCategoryActive] = useState(false);
    const [isMethodEditActive, setIsMethodEditActive] = useState(false);
    const [isDeleteConfirmMethodActive, setIsDeleteConfirmMethodActive] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [categoryToDelete, setCategoryToDelete] = useState({});
    const [selectedMethod, setSelectedMethod] = useState({});
    const [methodToDelete, setMethodToDelete] = useState({});

    const onSubmitCategory = async (category) => {
        let newCategories = [];
        if(category.order == '')
        {
          category.order = null;
        }
        if(!category.id) {
            let newCategory = (await categoryService.insert(getAccessTokenSilently, getAccessTokenWithPopup, category)).data;
            newCategories = [...categories, newCategory];
        }
        else {
          await categoryService.update(getAccessTokenSilently, getAccessTokenWithPopup, category);
          newCategories = categories.map(q => {
            return (q.id === category.id)? category : q;
          });
        }
        newCategories = newCategories.sort((a,b) => a.order - b.order);
        setCategories(newCategories);
        setIsCategoryEditActive(false);
    }

    const onCancelCategoryEdit = function() {
      setIsCategoryEditActive(false);
    }

    function debounce(func, timeout = 500){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }

    const changeWeight = async (weight, answerMethodWeight) => {
      if(answerMethodWeight.weight == '')
      {
        answerMethodWeight.weight = null;
        if(answerMethodWeight.id) {
          await answerMethodWeightService.delete(getAccessTokenSilently, getAccessTokenWithPopup, answerMethodWeight.id);
        }
      }
      else {
        if(!answerMethodWeight.id)
        {
          let newAnswerMethodWeight = await answerMethodWeightService.insert(getAccessTokenSilently, getAccessTokenWithPopup, answerMethodWeight);
          answerMethodWeight.id = newAnswerMethodWeight.data.id;
        }
        else {
          await answerMethodWeightService.update(getAccessTokenSilently, getAccessTokenWithPopup, answerMethodWeight);
        }
      }
      setMethods([...methods]);
    }

    const onChangeWeight = async (evt, answerMethodWeight) => {
      answerMethodWeight.weight = evt.target.value;
      setMethods([...methods]);
      debounce(async () => changeWeight(answerMethodWeight.weight, answerMethodWeight) )();
    }
    

    const onSubmitMethod = async (method) => {
      let newMethods = [];
      if(method.order == '')
        {
          method.order = null;
        }
      if(!method.id) {
          let newMethod = (await methodService.insert(getAccessTokenSilently, getAccessTokenWithPopup, method)).data;
          newMethod.answerMethodWeight = {methodId: newMethod.id, answerId: answerId, weight:''};
          newMethods = [...methods, newMethod];
      }
      else {
        await methodService.update(getAccessTokenSilently, getAccessTokenWithPopup, method);
        newMethods = methods.map(q => {
          return (q.id === method.id)? method : q;
        });
      }
      newMethods = newMethods.sort((a,b) => {
        if (a.order === b.order){
          return a.text < b.text ? -1 : 1
        } else {
          return a.order < b.order ? -1 : 1
        }
      });
      setMethods(newMethods);
      setIsMethodEditActive(false);
    }

    const onCancelMethodEdit = function() {
      setIsMethodEditActive(false);
    }

    const onDeleteButtonCategoryClicked = async () => {
      await categoryService.delete(getAccessTokenSilently, getAccessTokenWithPopup, categoryToDelete.id);
      let newCategories = categories.filter((q) => q.id !== categoryToDelete.id);
      setCategories(newCategories);
      setIsDeleteConfirmCategoryActive(false);
      
    }

    const onDeleteButtonMethodClicked = async () => {
      await methodService.delete(getAccessTokenSilently, getAccessTokenWithPopup, methodToDelete.id);
      let newMethods = methods.filter((q) => q.id !== methodToDelete.id);
      setMethods(newMethods);
      setIsDeleteConfirmMethodActive(false);
      
    }

    useEffect(() => {
      (async () => {
        setAnswer(await answerService.get(getAccessTokenSilently, getAccessTokenWithPopup, answerId));
        setCategories(await categoryService.getMethodCategories(getAccessTokenSilently, getAccessTokenWithPopup));
        let fetchedMethods = await methodService.getMethods(getAccessTokenSilently, getAccessTokenWithPopup);
        let fetchedAnswerMethodWeights = await answerService.getAllAnswerMethodWeightsForAnswer(getAccessTokenSilently, getAccessTokenWithPopup, answerId);
   
        fetchedMethods.map((method, index) => {
          method.answerMethodWeight = {methodId: method.id, answerId: answerId};
          fetchedAnswerMethodWeights.map((answerMethodWeight, index) => {
            if(method.id === answerMethodWeight.methodId) {
              method.answerMethodWeight = answerMethodWeight;
            }
          });
        });
        setMethods(fetchedMethods);
        setAnswerMethodWeights(fetchedAnswerMethodWeights);
      })();
      
    }, []);
    return <div>
    <h1 className="u-margin-top-xl"><a href="#" onClick={() => {navigate('/question/' + answer.questionId)}}><i className="fa-solid fa-arrow-left"></i></a>&nbsp;&nbsp;Antwoord: {answer.description}</h1>
    <h2 className="u-margin-top-xl">Kenmerken van hulpverleners</h2>
    <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsCategoryEditActive(true); setSelectedCategory({type: 'method'});}}>
      <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
      Nieuwe categorie
    </button>
    <br/><br/><br/><br/>

    <ul className="a-list a-list--lined">

      {categories.map((category, index) => (  
                      <MethodCategoryItem key={index}
                      {...{categories}} 
                      {...{methods}} 
                      {...{category}} 
                      {...{setIsMethodEditActive}} 
                      {...{setSelectedMethod}} 
                      {...{setIsCategoryEditActive}} 
                      {...{setSelectedCategory}} 
                      {...{setIsDeleteConfirmCategoryActive}} 
                      {...{setCategoryToDelete}} 
                      {...{setIsDeleteConfirmMethodActive}} 
                      {...{setMethodToDelete}} 
                      {...{onChangeWeight}} 
                      ></MethodCategoryItem>
              ))}  
   
   </ul><br/><br/><br/><br/><br/>
                    
   {isDeleteConfirmCategoryActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze categorie wil verwijderen? </p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonCategoryClicked}>Ja, ik wil ze verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isCategoryEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isCategoryEditActive && 
      <CategoryDetails onSubmit={onSubmitCategory} onCancel={onCancelCategoryEdit} {...{selectedCategory}}/>
    }
    </div>
    <div className={isCategoryEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsCategoryEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
    

    {isDeleteConfirmMethodActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmMethodActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je dit kenmerk wil verwijderen? Denk eraan dat alle gewichten die hiermee verbonden zijn zullen verdwijnen!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonMethodClicked}>Ja, ik wil het verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmMethodActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isMethodEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isMethodEditActive && 
      <MethodDetails onSubmit={onSubmitMethod} onCancel={onCancelMethodEdit} {...{selectedMethod}}/>
    }
    </div>
    <div className={isMethodEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsMethodEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Weight;

