import React from 'react';
import { Button } from '@acpaas-ui/react-components';

const MethodCategoryItem = ({
    categories, 
    methods, 
    category = {}, 
    setIsMethodEditActive, 
    setSelectedMethod, 
    setIsCategoryEditActive, 
    setSelectedCategory, 
    setIsDeleteConfirmCategoryActive, 
    setCategoryToDelete, 
    setIsDeleteConfirmMethodActive,
    setMethodToDelete,
    onChangeWeight

    }) => {
    const rowStyle = { cursor: "pointer"};
    return (
        <div>
            
            <li key={category.id} className="a-list__item">
                <a href="#" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{category.code}</span>
                    <br/>
                    <span className="small">{category.name}</span>
                    </span>
                    <button className="a-button has-icon-left" aria-label="Nieuw kenmerk" onClick={() => {setIsMethodEditActive(true); setSelectedMethod({category:category.id});}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
                    Nieuw kenmerk
                    </button>&nbsp;
                    <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsCategoryEditActive(true); setSelectedCategory(category);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    {/*<span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" onClick={() => {setIsDeleteConfirmCategoryActive(true); setCategoryToDelete(category);}}></i></span>*/}
                </div>
                </a>
            </li>
            {methods.filter((method) => method.category === category.id).map((method, index) => (
                <li key={index} className="a-list__item" style={{'marginLeft':'2rem'}}>
                <a tabIndex="-1" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{method.code}</span>
                    <br/>
                    <span className="small">{method.name}</span>
                    </span>
                    <div style={{'marginTop':'14px'}}>Gewicht:&nbsp;&nbsp;</div>
                    <div className="a-input" style={{'marginRight':'6rem'}}>
                        <input type="text"
                        style={{'width': '6rem'}}
                        value={method.answerMethodWeight.weight}
                        onChange={evt => onChangeWeight(evt, method.answerMethodWeight)}
                        placeholder="gewicht"/>
                    </div>
                    <button tabIndex="-1" className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsMethodEditActive(true); setSelectedMethod(method);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" style={rowStyle} onClick={() => {setIsDeleteConfirmMethodActive(true); setMethodToDelete(method);}}></i></span>
                </div>
                </a>
                </li>
            ))}
        </div>
    );
};

export default MethodCategoryItem;