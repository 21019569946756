import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './styles/main.scss';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter><Auth0Provider
    domain="miaauth.eu.auth0.com"
    clientId="voX5QXWDGGRxATqEhrR6alAUn34axqnf"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://adminapi.miazoekhulp.be"
    }}
  >
    <App />
    </Auth0Provider>
    </BrowserRouter>);
