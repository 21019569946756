import { useEffect , useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Providers.scss';
import { useAuth0 } from "@auth0/auth0-react";
import providerService from '../../services/provider';
import categoryService from '../../services/category';
import ProviderDetails from './ProviderDetails';
import CategoryDetails from '../Category/CategoryDetails';

const Providers = () => {
    const navigate = useNavigate();

    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [providers, setProviders] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [isDeleteConfirmActice, setIsDeleteConfirmActice] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState({});
    const [providerToDelete, setProviderToDelete] = useState({});
    const [isCategoryEditActive, setIsCategoryEditActive] = useState(false);
    const [isDeleteConfirmCategoryActive, setIsDeleteConfirmCategoryActive] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [categoryToDelete, setCategoryToDelete] = useState({});
    
    const rowStyle = { cursor: "pointer"};


    const onSubmitCategory = async (category) => {
      let newCategories = [];
      if(category.order == '')
      {
        category.order = null;
      }
      if(!category.id) {
          let newCategory = (await categoryService.insert(getAccessTokenSilently, getAccessTokenWithPopup, category)).data;
          newCategories = [...categories, newCategory];
      }
      else {
        await categoryService.update(getAccessTokenSilently, getAccessTokenWithPopup, category);
        newCategories = categories.map(q => {
          return (q.id === category.id)? category : q;
        });
      }
      newCategories = newCategories.sort((a,b) => a.order - b.order);
      setCategories(newCategories);
      setIsCategoryEditActive(false);
  }

  const onCancelCategoryEdit = function() {
    setIsCategoryEditActive(false);
  }


  const onDeleteButtonCategoryClicked = async () => {
    await categoryService.delete(getAccessTokenSilently, getAccessTokenWithPopup, categoryToDelete.id);
    let newCategories = categories.filter((q) => q.id !== categoryToDelete.id);
    setCategories(newCategories);
    setIsDeleteConfirmCategoryActive(false);
    
  }

    const onSubmitProvider = async (provider) => {
        let newProviders = [];
        if(!provider.id) {
            let newProvider = (await providerService.insert(getAccessTokenSilently, getAccessTokenWithPopup, provider)).data;
            newProviders = [...providers, newProvider];
        }
        else {
          await providerService.update(getAccessTokenSilently, getAccessTokenWithPopup, provider);
          newProviders = providers.map(q => {
            return (q.id === provider.id)? provider : q;
          });
        }
        setProviders(newProviders);

        let newCategories = [...categories];
        newCategories.map((category, index) => {
          category.providers = newProviders.filter((provider) => provider.categoryId === category.id);
          category.providers = category.providers.sort((a,b) => {return a.order - b.order});
        });
        setCategories(newCategories);

        setIsEditActive(false);
    }

    const onCancelProviderEdit = function() {
      setIsEditActive(false);
    }

    const onDeleteButtonClicked = async () => {
      await providerService.delete(getAccessTokenSilently, getAccessTokenWithPopup, providerToDelete.id);
      let newProviders = providers.filter((q) => q.id !== providerToDelete.id);
      setProviders(newProviders);

      let newCategories = [...categories];
      newCategories.map((category, index) => {
        category.providers = newProviders.filter((provider) => provider.categoryId === category.id);
        category.providers = category.providers.sort((a,b) => {return a.order - b.order});
      });
      setCategories(newCategories);

      setIsDeleteConfirmActice(false);
      
    }

    useEffect(() => {
      (async () => {
        let theProviders = await providerService.getProviders(getAccessTokenSilently, getAccessTokenWithPopup);
        let theCategories = await categoryService.getProviderCategories(getAccessTokenSilently, getAccessTokenWithPopup);
   
        theCategories.map((category, index) => {
          category.providers = theProviders.filter((provider) => provider.categoryId === category.id);
          category.providers = category.providers.sort((a,b) => {return a.order - b.order});
        });

        setCategories(theCategories);
        setProviders(theProviders);
      })();
      
    }, []);
    return <div>
    <h2 className="u-margin-top-xl"><a href="#"><i className="fa-solid fa-arrow-left" onClick={() => {navigate('/')}}></i></a>&nbsp;&nbsp;Hulpverleners</h2>

    <br/>
    <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsCategoryEditActive(true); setSelectedCategory({type: 'provider'});}}>
      <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
      Nieuwe praktijk
    </button>
    <ul className="a-list a-list--lined">
    {(categories && categories.length)? categories.map((category, categoryIndex) => (  
      <div key={categoryIndex}>
      <h3 className="u-margin-top-xl">{category.name}</h3>
      <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsEditActive(true); setSelectedCategory(category); setSelectedProvider({auth0Id: 'temporary',hidden:false, categoryId:category.id, languages:[], beliefs: [], ageGroups: [], refundOptions: [], consultationType: "LIVE", availableForNewPatients: true, certifications: "[]", practiceHours: "[]", pricePerSession: 0, avgSessionMinutes: 0});}}>
        <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
        Nieuwe hulpverlener
      </button>
      <br/><br/><br/>
      {(category.providers && category.providers.length)? category.providers.map((provider, index) => (  
                    <li key={index} className="a-list__item">
                      <a className="a-avatar-list__item-container">

                        <div className="a-avatar-list__content">
                          <span className="a-list__text" style={rowStyle} onClick={() => {setIsEditActive(true); setSelectedProvider(provider); setSelectedCategory(category);}}>
                            <span className="avatar-list__name paragraph">{provider.name} {provider.surName}</span>
                          </span>
                          <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsEditActive(true); setSelectedProvider(provider); setSelectedCategory(category);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                          </button>&nbsp;
                          <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" style={rowStyle} onClick={() => {setIsDeleteConfirmActice(true); setProviderToDelete(provider);}}></i></span>
                        </div>
                      </a>
      
                </li>
              )):null}  
   </div>)):null}
   </ul><br/><br/><br/><br/><br/>
      
   {isDeleteConfirmCategoryActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze praktijk wil verwijderen? </p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonCategoryClicked}>Ja, ik wil ze verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isCategoryEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isCategoryEditActive && 
      <CategoryDetails onSubmit={onSubmitCategory} onCancel={onCancelCategoryEdit} {...{selectedCategory}}/>
    }
    </div>
    <div className={isCategoryEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsCategoryEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
    
   {isDeleteConfirmActice && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmActice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze hulpverlener wil verwijderen? Denk eraan dat alles zal verwijderd worden!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonClicked}>Ja, ik wil verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmActice(false)}}>Annuleren</button>
          </div>
        </div>
      </div>
    }
    <div className={isEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isEditActive && 
      <ProviderDetails onSubmit={onSubmitProvider} onCancel={onCancelProviderEdit} {...{selectedProvider}} {...{selectedCategory}}/>
    }
    </div>
    <div className={isEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Providers;

