import getAccessToken from "./accessTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../Config";

const pictureService = {
    post: async function(getAccessTokenSilently, getAccessTokenWithPopup, picture) {
        try {
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/picture/post`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                  },
                body: picture
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    }

}

export default pictureService;