import React, { useEffect, useState } from 'react';
import { Config } from "../Config";

const GoogleMapsLoader = () => {
  const [loaded, setLoaded] = useState(false);
  const apiKey = Config.getGoogleMapsApiKey();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=" + apiKey+ "&libraries=places";
    script.onload = () => setLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default GoogleMapsLoader;
