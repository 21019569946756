import { useEffect , useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Weight.scss';
import { useAuth0 } from "@auth0/auth0-react";
import answerOrganisationWeightService from '../../services/anwserOrganisationWeight';
import categoryService from '../../services/category';
import organisationService from '../../services/organisation';
import CategoryDetails from '../Category/CategoryDetails';
import OrganisationDetails from './OrganisationDetails';
import OrganisationCategoryItem from '../Category/OrganisationCategoryItem';
import answerService from '../../services/answer';

const Weight = () => {
    const navigate = useNavigate();
    const { answerId } = useParams();
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [categories, setCategories] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [answerOrganisationWeights, setAnswerOrganisationWeights] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [isCategoryEditActive, setIsCategoryEditActive] = useState(false);
    const [isDeleteConfirmCategoryActive, setIsDeleteConfirmCategoryActive] = useState(false);
    const [isOrganisationEditActive, setIsOrganisationEditActive] = useState(false);
    const [isDeleteConfirmOrganisationActive, setIsDeleteConfirmOrganisationActive] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [categoryToDelete, setCategoryToDelete] = useState({});
    const [selectedOrganisation, setSelectedOrganisation] = useState({});
    const [organisationToDelete, setOrganisationToDelete] = useState({});

    const onSubmitCategory = async (category) => {
        let newCategories = [];
        if(category.order == '')
        {
          category.order = null;
        }
        if(!category.id) {
            let newCategory = (await categoryService.insert(getAccessTokenSilently, getAccessTokenWithPopup, category)).data;
            newCategories = [...categories, newCategory];
        }
        else {
          await categoryService.update(getAccessTokenSilently, getAccessTokenWithPopup, category);
          newCategories = categories.map(q => {
            return (q.id === category.id)? category : q;
          });
        }
        newCategories = newCategories.sort((a,b) => a.order - b.order);
        setCategories(newCategories);
        setIsCategoryEditActive(false);
    }

    const onCancelCategoryEdit = function() {
      setIsCategoryEditActive(false);
    }

    function debounce(func, timeout = 500){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }

    const changeWeight = async (weight, answerOrganisationWeight) => {
      if(answerOrganisationWeight.weight == '')
      {
        answerOrganisationWeight.weight = null;
        if(answerOrganisationWeight.id) {
          await answerOrganisationWeightService.delete(getAccessTokenSilently, getAccessTokenWithPopup, answerOrganisationWeight.id);
        }
      }
      else {
        if(!answerOrganisationWeight.id)
        {
          let newAnswerOrganisationWeight = await answerOrganisationWeightService.insert(getAccessTokenSilently, getAccessTokenWithPopup, answerOrganisationWeight);
          answerOrganisationWeight.id = newAnswerOrganisationWeight.data.id;
        }
        else {
          await answerOrganisationWeightService.update(getAccessTokenSilently, getAccessTokenWithPopup, answerOrganisationWeight);
        }
      }
      setOrganisations([...organisations]);
    }

    const onChangeWeight = async (evt, answerOrganisationWeight) => {
      answerOrganisationWeight.weight = evt.target.value;
      setOrganisations([...organisations]);
      debounce(async () => changeWeight(answerOrganisationWeight.weight, answerOrganisationWeight) )();
    }
    

    const onSubmitOrganisation = async (organisation) => {
      let newOrganisations = [];
      if(organisation.order == '')
        {
          organisation.order = null;
        }
      if(!organisation.id) {
          let newOrganisation = (await organisationService.insert(getAccessTokenSilently, getAccessTokenWithPopup, organisation)).data;
          newOrganisation.answerOrganisationWeight = {organisationId: newOrganisation.id, answerId: answerId, weight:''};
          newOrganisations = [...organisations, newOrganisation];
      }
      else {
        await organisationService.update(getAccessTokenSilently, getAccessTokenWithPopup, organisation);
        newOrganisations = organisations.map(q => {
          return (q.id === organisation.id)? organisation : q;
        });
      }
      newOrganisations = newOrganisations.sort((a,b) => {
        if (a.order === b.order){
          return a.text < b.text ? -1 : 1
        } else {
          return a.order < b.order ? -1 : 1
        }
      });
      setOrganisations(newOrganisations);
      setIsOrganisationEditActive(false);
    }

    const onCancelOrganisationEdit = function() {
      setIsOrganisationEditActive(false);
    }

    const onDeleteButtonCategoryClicked = async () => {
      await categoryService.delete(getAccessTokenSilently, getAccessTokenWithPopup, categoryToDelete.id);
      let newCategories = categories.filter((q) => q.id !== categoryToDelete.id);
      setCategories(newCategories);
      setIsDeleteConfirmCategoryActive(false);
      
    }

    const onDeleteButtonOrganisationClicked = async () => {
      await organisationService.delete(getAccessTokenSilently, getAccessTokenWithPopup, organisationToDelete.id);
      let newOrganisations = organisations.filter((q) => q.id !== organisationToDelete.id);
      setOrganisations(newOrganisations);
      setIsDeleteConfirmOrganisationActive(false);
      
    }

    useEffect(() => {
      (async () => {
        setAnswer(await answerService.get(getAccessTokenSilently, getAccessTokenWithPopup, answerId));
        setCategories(await categoryService.getOrganisationCategories(getAccessTokenSilently, getAccessTokenWithPopup));
        let fetchedOrganisations = await organisationService.getOrganisations(getAccessTokenSilently, getAccessTokenWithPopup);
        let fetchedAnswerOrganisationWeights = await answerService.getAllAnswerOrganisationWeightsForAnswer(getAccessTokenSilently, getAccessTokenWithPopup, answerId);
   
        fetchedOrganisations.map((organisation, index) => {
          organisation.answerOrganisationWeight = {organisationId: organisation.id, answerId: answerId};
          fetchedAnswerOrganisationWeights.map((answerOrganisationWeight, index) => {
            if(organisation.id === answerOrganisationWeight.organisationId) {
              organisation.answerOrganisationWeight = answerOrganisationWeight;
            }
          });
        });
        setOrganisations(fetchedOrganisations);
        setAnswerOrganisationWeights(fetchedAnswerOrganisationWeights);
      })();
      
    }, []);
    return <div>
    <h1 className="u-margin-top-xl"><a href="#" onClick={() => {navigate('/question/' + answer.questionId)}}><i className="fa-solid fa-arrow-left"></i></a>&nbsp;&nbsp;Antwoord: {answer.description}</h1>
    <h2 className="u-margin-top-xl">Organisaties</h2>
    <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsCategoryEditActive(true); setSelectedCategory({type: 'organisation'});}}>
      <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
      Nieuwe categorie
    </button>
    <br/><br/><br/><br/>

    <ul className="a-list a-list--lined">

      {categories.map((category, index) => (  
                      <OrganisationCategoryItem key={index}
                      {...{categories}} 
                      {...{organisations}} 
                      {...{category}} 
                      {...{setIsOrganisationEditActive}} 
                      {...{setSelectedOrganisation}} 
                      {...{setIsCategoryEditActive}} 
                      {...{setSelectedCategory}} 
                      {...{setIsDeleteConfirmCategoryActive}} 
                      {...{setCategoryToDelete}} 
                      {...{setIsDeleteConfirmOrganisationActive}} 
                      {...{setOrganisationToDelete}} 
                      {...{onChangeWeight}} 
                      ></OrganisationCategoryItem>
              ))}  
   
   </ul><br/><br/><br/><br/><br/>
                    
   {isDeleteConfirmCategoryActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze categorie wil verwijderen? </p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonCategoryClicked}>Ja, ik wil ze verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmCategoryActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isCategoryEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isCategoryEditActive && 
      <CategoryDetails onSubmit={onSubmitCategory} onCancel={onCancelCategoryEdit} {...{selectedCategory}}/>
    }
    </div>
    <div className={isCategoryEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsCategoryEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
    

    {isDeleteConfirmOrganisationActive && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmOrganisationActive(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je dit kenmerk wil verwijderen? Denk eraan dat alle gewichten die hiermee verbonden zijn zullen verdwijnen!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonOrganisationClicked}>Ja, ik wil het verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmOrganisationActive(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isOrganisationEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isOrganisationEditActive && 
      <OrganisationDetails onSubmit={onSubmitOrganisation} onCancel={onCancelOrganisationEdit} {...{selectedOrganisation}}/>
    }
    </div>
    <div className={isOrganisationEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsOrganisationEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Weight;

