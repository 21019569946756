const getAccessToken = async (getAccessTokenSilently, getAccessTokenWithPopup) => {
    let accessToken;
    try {
        accessToken = await getAccessTokenSilently({
            authorizationParams: {
            audience: `https://adminapi.miazoekhulp.be`
            },
        });
    }
    catch(e) {
        accessToken = await getAccessTokenWithPopup({
            authorizationParams: {
            audience: `https://adminapi.miazoekhulp.be`
            },
        });
    }
    return accessToken;

}

export default getAccessToken;