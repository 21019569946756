import React from 'react';
import { useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect, useState } from 'react';
import pictureService from '../../services/picture';
import { ImageInput } from '../ImageInput/ImageInput';
import { useAuth0 } from "@auth0/auth0-react";

const OrganisationDetails = ({onSubmit, onCancel, selectedOrganisation = {}}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm(
        {
            defaultValues: selectedOrganisation
        }
    );

    const [pictureUrl, setPictureUrl] = useState('');
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    useEffect(() => {
        reset(selectedOrganisation);
        setPictureUrl(selectedOrganisation.pictureUrl);  
    }, [selectedOrganisation])  


    const save = (data) => {
        data.pictureUrl = pictureUrl;
        onSubmit(data);
    };


    function dataURLtoBlob(dataURL) {
        const parts = dataURL.split(",");
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uint8Array = new Uint8Array(rawLength);
        
        for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uint8Array], { type: contentType });
    }



    return (
        <div style={{width:'100%', overflow:'scroll'}}>
        {selectedOrganisation.name && 
            <span><h1>Organisatie</h1><p>{selectedOrganisation.name}</p><br/></span>
        }
        {!selectedOrganisation.name && 
            <span><h1>Nieuwe organisatie</h1><br/></span>
        }
        <form onSubmit={handleSubmit(save)} className="a-form" style={{width:'100%'}}>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Code</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een code in voor identificatie</small>
                <input type="text"
                {...register("code")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Naam</label>
                <small className="a-input__description" id="text-field-description">Geef het kenmerk in</small>
                <input type="text"
                {...register("name", {required: true})}
                aria-invalid={errors.text ? "true" : "false"} />
                {errors.text && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Beschrijving</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een beschrijving in</small>
                <input type="text"
                {...register("description")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Doelpubliek</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een doelpubliek in</small>
                <input type="text"
                {...register("targetAudience")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Probleemgebied</label>
                <small className="a-input__description" id="text-field-description">Op welk problemen is dit van toepassing?</small>
                <input type="text"
                {...register("problemArea")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Diagnose</label>
                <small className="a-input__description" id="text-field-description">Op welke diagnoses is dit van toepassing?</small>
                <input type="text"
                {...register("diagnose")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Locatie</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een locatie in</small>
                <input type="text"
                {...register("location")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Contact</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel contactgegevens in</small>
                <input type="text"
                {...register("contact")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Materiaal</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel materiaal in</small>
                <input type="text"
                {...register("material")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Link</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een link in</small>
                <input type="text"
                {...register("link")} />
            </div>
            <div className="a-input">
            <label className="a-input__label" htmlFor="text-field">Beeld</label>
            <small className="a-input__description" id="text-field-description">Upload een screenshot of logo</small>
            <ImageInput
                    onChange={(file) => {
                     
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = (event) => {
                        const img = new Image();
                        if(event.target)
                        {
                          img.src = event.target?.result;

                          img.onload = () => {

                            const maxDimension = 800; // Set a maximum width or height, e.g., 800px
                            let width = img.width;
                            let height = img.height;

                            // Calculate the new dimensions while maintaining the aspect ratio
                            if (width > height) {
                                if (width > maxDimension) {
                                    height *= maxDimension / width;
                                    width = maxDimension;
                                }
                            } else {
                                if (height > maxDimension) {
                                    width *= maxDimension / height;
                                    height = maxDimension;
                                }
                            }

                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            canvas.width = width;
                            canvas.height = height;
                            if(ctx)
                            {
                              ctx.drawImage(img, 0, 0, width, height);

                              // Adjust the quality parameter to control the compression level
                              const compressedDataURL = canvas.toDataURL("image/jpeg", 0.5);

                              // Convert the data URL to a Blob
                              const compressedFile = dataURLtoBlob(compressedDataURL);

                              // Create a FormData object and append the compressed file
                              const formData = new FormData();
                              formData.append("binTypeImage", compressedFile, file.name);

                              // Send the compressed image to the server
                              pictureService.post(getAccessTokenSilently, getAccessTokenWithPopup, formData).then((response) =>
                                {setPictureUrl(response.data.url);}
                              );
                            }

                          };
                        }
                      };
                    }}
                    imageUrl={pictureUrl}
                  />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Order</label>
                <small className="a-input__description" id="text-field-description">Geef een cijfer voor de volgorde in</small>
                <input type="text"
                {...register("order")}/>
            </div>
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>
    );
};

export default OrganisationDetails;