import getAccessToken from "./accessTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../Config";

const answerService = {
    getAllSubQuestions: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + '/answers/';

            const response = await fetch(url + id + '/questions', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getAllAnswerMethodWeightsForAnswer: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url + id + '/answermethodweights', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getAllAnswerOrganisationWeightsForAnswer: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url + id + '/answerorganisationweights', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getAllAnswerMediaWeightsForAnswer: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url + id + '/answermediaweights', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    get: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url + id , {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    insert: async function(getAccessTokenSilently, getAccessTokenWithPopup, answer) {
        try {

            answer.id = uuidv4();
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(answer)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    update: async function(getAccessTokenSilently, getAccessTokenWithPopup, answer) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(answer)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    delete: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);            
            const url = Config.getBackendUrl() + `/answers/`;
            
            const response = await fetch(url + id, {
                method: 'DELETE',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    }
}

export default answerService;