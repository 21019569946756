function getConfigVar(key) {
  const val = process.env[key];
  if (val === undefined) {
    throw new Error(`The variable with name ${key} is not set in .env.`);
  }
  return val;
}

export class Config {
  static getBackendUrl() {
    return getConfigVar("REACT_APP_BACKEND_URL");
  }
  static getGoogleMapsApiKey() {
    return getConfigVar("REACT_APP_GOOGLE_MAPS_API_KEY");
  }
}
