import { useEffect , useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Question.scss';
import { useAuth0 } from "@auth0/auth0-react";
import questionService from '../../services/question';
import answerService from '../../services/answer';
import AnswerDetails from './AnswerDetails';

const Question = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [question, setQuestion] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [isDeleteConfirmActice, setIsDeleteConfirmActice] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState({questionId:id});
    const [answerToDelete, setAnswerToDelete] = useState({});
    const rowStyle = { cursor: "pointer"};
    const onSubmitAnswer = async (answer) => {
        let newAnswers = [];
        if(!answer.id) {
            let newAnswer = (await answerService.insert(getAccessTokenSilently, getAccessTokenWithPopup, answer)).data;
            newAnswers = [...answers, newAnswer];
        }
        else {
          await answerService.update(getAccessTokenSilently, getAccessTokenWithPopup, answer);
          newAnswers = answers.map(q => {
            return (q.id === answer.id)? answer : q;
          });
        }
        newAnswers = newAnswers.sort((a,b) => a.order - b.order);
        setAnswers(newAnswers);
        setIsEditActive(false);
    }

    const onCancelAnswerEdit = function() {
      setIsEditActive(false);
    }

    const onDeleteButtonClicked = async () => {
      await answerService.delete(getAccessTokenSilently, getAccessTokenWithPopup, answerToDelete.id);
      let newAnswers = answers.filter((q) => q.id !== answerToDelete.id);
      setAnswers(newAnswers);
      setIsDeleteConfirmActice(false);
      
    }

    const goBack = () => {
      if(question.parentAnswerId) {
        navigate('/answer/' + question.parentAnswerId);
      }
      else {
        navigate('/questions/');
      }
    }

    useEffect(() => {
      (async () => {
        setQuestion(await questionService.get(getAccessTokenSilently, getAccessTokenWithPopup, id));
        setAnswers(await questionService.getAnswersForQuestion(getAccessTokenSilently, getAccessTokenWithPopup, id));
        
      })();
      
    }, []);
    return <div>
    <h1 className="u-margin-top-xl"><a href="#"><i className="fa-solid fa-arrow-left" onClick={() => goBack()}></i></a>&nbsp;&nbsp;Vraag: {question.text}</h1>
    <h2 className="u-margin-top-xl">Antwoorden</h2>
    <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsEditActive(true); setSelectedAnswer({questionId:id, stopQuestionnaire: false});}}>
      <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
      Nieuw antwoord
    </button>
    <br/><br/><br/><br/>

    <ul className="a-list a-list--lined">

      {answers.map((answer, index) => (  
                    <li key={index} className="a-list__item">
                      <a className="a-avatar-list__item-container">

                        <div className="a-avatar-list__content">
                          <span className="a-list__text" style={rowStyle} onClick={() => {navigate("/answer/" + answer.id);}}>
                            <span className="avatar-list__name paragraph">{answer.id}</span>
                            <br/>
                            <span className="small">{answer.description}</span>
                          </span>
                          <button className="a-button a-button--s has-icon" aria-label="Profiel" onClick={() => {navigate("/methodweight/" + answer.id);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-scale-unbalanced"></i></span>
                          </button>&nbsp;
                          <button className="a-button a-button--s has-icon a-button--danger" aria-label="Organisaties" onClick={() => {navigate("/organisationweight/" + answer.id);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-scale-unbalanced"></i></span>
                          </button>&nbsp;
                          <button className="a-button a-button--s has-icon a-button--success" aria-label="Media" onClick={() => {navigate("/mediaweight/" + answer.id);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-scale-unbalanced"></i></span>
                          </button>&nbsp;
                          <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsEditActive(true); setSelectedAnswer(answer);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                          </button>&nbsp;
                          <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" onClick={() => {setIsDeleteConfirmActice(true); setAnswerToDelete(answer);}}></i></span>
                        </div>
                      </a>
      
                </li>
              ))}  
   
   </ul><br/><br/><br/><br/><br/>
    
   {isDeleteConfirmActice && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmActice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je dit antwoord wil verwijderen? Denk eraan dat alle scores die hiermee verbonden zijn zullen verdwijnen!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonClicked}>Ja, ik wil het verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmActice(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isEditActive && 
      <AnswerDetails onSubmit={onSubmitAnswer} onCancel={onCancelAnswerEdit} {...{selectedAnswer}}/>
    }
    </div>
    <div className={isEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Question;

