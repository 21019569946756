import React from 'react';
import { Button } from '@acpaas-ui/react-components';

const MediaCategoryItem = ({
    categories, 
    medias, 
    category = {}, 
    setIsMediaEditActive, 
    setSelectedMedia, 
    setIsCategoryEditActive, 
    setSelectedCategory, 
    setIsDeleteConfirmCategoryActive, 
    setCategoryToDelete, 
    setIsDeleteConfirmMediaActive,
    setMediaToDelete,
    onChangeWeight

    }) => {
    const rowStyle = { cursor: "pointer"};

    return (
        <div>
            
            <li key={category.id} className="a-list__item">
                <a href="#" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{category.code}</span>
                    <br/>
                    <span className="small">{category.name}</span>
                    </span>
                    <button className="a-button has-icon-left" aria-label="Nieuw kenmerk" onClick={() => {setIsMediaEditActive(true); setSelectedMedia({mediaCategory:category.id});}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
                    Nieuw medium
                    </button>&nbsp;
                    <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsCategoryEditActive(true); setSelectedCategory(category);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    {/*<span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" onClick={() => {setIsDeleteConfirmCategoryActive(true); setCategoryToDelete(category);}}></i></span>*/}
                </div>
                </a>
            </li>
            {medias.filter((media) => media.mediaCategory === category.id).map((media, index) => (
                <li key={index} className="a-list__item" style={{'marginLeft':'2rem'}}>
                <a tabIndex="-1" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{media.code}</span>
                    <br/>
                    <span className="small">{media.title}</span>
                    </span>
                    <div style={{'marginTop':'14px'}}>Gewicht:&nbsp;&nbsp;</div>
                    <div className="a-input" style={{'marginRight':'6rem'}}>
                        <input type="text"
                        style={{'width': '6rem'}}
                        value={media.answerMediaWeight.weight}
                        onChange={evt => onChangeWeight(evt, media.answerMediaWeight)}
                        placeholder="gewicht"/>
                    </div>
                    <button tabIndex="-1" className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsMediaEditActive(true); setSelectedMedia(media);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" style={rowStyle} onClick={() => {setIsDeleteConfirmMediaActive(true); setMediaToDelete(media);}}></i></span>
                </div>
                </a>
                </li>
            ))}
        </div>
    );
};

export default MediaCategoryItem;