
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  function doLogout() {
    logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
  }

  return (
    isAuthenticated && (
      <div>
        <table>
          <tbody>
          <tr>
            <td>
              <a href="/logout" onClick={doLogout}>Logout</a>
            </td>
            <td><img src={user.picture} alt={user.name} width="42" style={{marginRight: '5px', marginLeft: '5px'}}/></td>
            <td>
              <b>{user.name}</b><br/>
              <p>{user.email}</p>
              </td>
          </tr>
          </tbody>
        </table>
        
      </div>
    )
  );
};

export default Profile;
