import React from 'react';
import { Button } from '@acpaas-ui/react-components';

const OrganisationCategoryItem = ({
    categories, 
    organisations, 
    category = {}, 
    setIsOrganisationEditActive, 
    setSelectedOrganisation, 
    setIsCategoryEditActive, 
    setSelectedCategory, 
    setIsDeleteConfirmCategoryActive, 
    setCategoryToDelete, 
    setIsDeleteConfirmOrganisationActive,
    setOrganisationToDelete,
    onChangeWeight

    }) => {

    const rowStyle = { cursor: "pointer"};
    return (
        <div>
            
            <li key={category.id} className="a-list__item">
                <a href="#" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{category.code}</span>
                    <br/>
                    <span className="small">{category.name}</span>
                    </span>
                    <button className="a-button has-icon-left" aria-label="Nieuw kenmerk" onClick={() => {setIsOrganisationEditActive(true); setSelectedOrganisation({organisationCategory:category.id});}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
                    Nieuwe organisatie
                    </button>&nbsp;
                    <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsCategoryEditActive(true); setSelectedCategory(category);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    {/*<span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" onClick={() => {setIsDeleteConfirmCategoryActive(true); setCategoryToDelete(category);}}></i></span>*/}
                </div>
                </a>
            </li>
            {organisations.filter((organisation) => organisation.organisationCategory === category.id).map((organisation, index) => (
                <li key={index} className="a-list__item" style={{'marginLeft':'2rem'}}>
                <a tabIndex="-1" className="a-avatar-list__item-container">

                <div className="a-avatar-list__content">
                    <span className="a-list__text">
                    <span className="avatar-list__name paragraph">{organisation.code}</span>
                    <br/>
                    <span className="small">{organisation.name}</span>
                    </span>
                    <div style={{'marginTop':'14px'}}>Gewicht:&nbsp;&nbsp;</div>
                    <div className="a-input" style={{'marginRight':'6rem'}}>
                        <input type="text"
                        style={{'width': '6rem'}}
                        value={organisation.answerOrganisationWeight.weight}
                        onChange={evt => onChangeWeight(evt, organisation.answerOrganisationWeight)}
                        placeholder="gewicht"/>
                    </div>
                    <button tabIndex="-1" className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsOrganisationEditActive(true); setSelectedOrganisation(organisation);}}>
                    <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                    </button>&nbsp;
                    <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" style={rowStyle} onClick={() => {setIsDeleteConfirmOrganisationActive(true); setOrganisationToDelete(organisation);}}></i></span>
                </div>
                </a>
                </li>
            ))}
        </div>
    );
};

export default OrganisationCategoryItem;