
  import { Link, Route, Routes } from "react-router-dom";
import {
  Header,
  Footer,
} from '@acpaas-ui/react-components';
/**
 * More Antwerp UI documentation can be found here:
 * https://digipolisantwerp.github.io/antwerp-ui_react/
 */
import './App.scss';
import Home from './components/Home/Home';
import Questions from './components/Question/Questions';
import Providers from './components/Provider/Providers';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Profile from './components/Profile/Profile';
import Question from './components/Question/Question';
import MethodWeight from './components/MethodWeight/Weight';
import MediaWeight from './components/MediaWeight/Weight';
import OrganisationWeight from './components/OrganisationWeight/Weight';
import Answer from './components/Answer/Answer';
import GoogleMapsLoader from './components/GoogleMapsLoader';


function App() {

  

  return (
    
    <div className="App">
      <div>
        <GoogleMapsLoader />
      </div>
      <header>
        <Header logoAlt="MIA logo." logoSrc="/img/mia.png">
            <div className="o-header__content-wrapper">
              <div className="o-header__menu-items">
                <div className="o-header__menu-item">
                  <Profile></Profile>
                </div>
              </div>
            </div>
          </Header>
      </header>
      <div className="main u-wrapper">
        <div className="u-container u-margin-top-xx u-margin-bottom-lg" role="main">
          <div className="row">
            <div className="col-xs-12">
              <Routes>
                <Route path="/login" element={<Login/>}></Route>
                <Route path="/logout" element={<Logout/>}></Route>
                <Route path="/" element={<Home/>} />
                <Route path="/questions" element={<Questions/>} />
                <Route path="/providers" element={<Providers/>} />
                <Route path="/question/:id" element={<Question/>} />
                <Route path="/methodweight/:answerId" element={<MethodWeight/>} />
                <Route path="/mediaweight/:answerId" element={<MediaWeight/>} />
                <Route path="/organisationweight/:answerId" element={<OrganisationWeight/>} />
                <Route path="/answer/:id" element={<Answer/>} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <footer>
      </footer>
    </div>
  );
}

export default App;
