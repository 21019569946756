import getAccessToken from "./accessTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../Config";

const signupService = {
    signupNormalUser: async function(getAccessTokenSilently, getAccessTokenWithPopup, signup) {
        try {
            signup.id = uuidv4();
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/signup/normaluser`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signup)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    signupPracticeUser: async function(getAccessTokenSilently, getAccessTokenWithPopup, signup) {
        try {
            signup.id = uuidv4();
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/signup/practiceuser`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signup)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },

}

export default signupService;