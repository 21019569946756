import { useAuth0 } from "@auth0/auth0-react";


const Login = prop => {
  const { loginWithRedirect } = useAuth0();

  return     <div>
    <h2 className="h4 u-margin-top">Login</h2>
    <button onClick={() => loginWithRedirect()}>Log In</button>
  </div>;
};

export default Login;
