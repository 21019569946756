import React from 'react';
import { useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect } from 'react';

const CategoryDetails = ({onSubmit, onCancel, selectedCategory = {}}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm(
        {
            defaultValues: selectedCategory
        }
    );

    useEffect(() => {
        reset(selectedCategory);
    }, [selectedCategory])  

    return (
        <div style={{width:'100%', overflow: 'scroll'}}>
        {selectedCategory.text && 
            <span><h1>Aanpassen</h1><p>{selectedCategory.text}</p><br/></span>
        }
        {!selectedCategory.text && 
            <span><h1>Nieuw</h1><br/></span>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="a-form" style={{width:'100%'}}>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Code</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een code in voor identificatie</small>
                <input type="text"
                {...register("code")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Naam</label>
                <small className="a-input__description" id="text-field-description">Geef de naam in</small>
                <input type="text"
                {...register("name", {required: true})}
                aria-invalid={errors.text ? "true" : "false"} />
                {errors.text && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Beschrijving</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een beschrijving in</small>
                <input type="text"
                {...register("description")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Order</label>
                <small className="a-input__description" id="text-field-description">Geef een cijfer voor de volgorde in</small>
                <input type="text"
                {...register("order")} />
            </div>
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>
    );
};

export default CategoryDetails;