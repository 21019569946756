import React from 'react';
import { useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect } from 'react';

const QuestionDetails = ({onSubmit, onCancel, selectedQuestion = {}}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm(
        {
            defaultValues: selectedQuestion
        }
    );

    useEffect(() => {
        reset(selectedQuestion);
    }, [selectedQuestion])  

    return (
        <div style={{width:'100%', overflow: 'scroll'}}>
        {selectedQuestion.text && 
            <span><h1>Vraag</h1><p>{selectedQuestion.text}</p><br/></span>
        }
        {!selectedQuestion.text && 
            <span><h1>Nieuwe vraag</h1><br/></span>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="a-form" style={{width:'100%'}}>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Code</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een code in voor identificatie</small>
                <input type="text"
                {...register("code")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Vraag</label>
                <small className="a-input__description" id="text-field-description">Geef de vraag in</small>
                <input type="text"
                {...register("description", {required: true})}
                aria-invalid={errors.text ? "true" : "false"} />
                {errors.text && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Uitleg over de vraag</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel meer uitleg over de vraag</small>
                <textarea
                    {...register("information")}
                    />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Order</label>
                <small className="a-input__description" id="text-field-description">Geef een cijfer voor de volgorde in</small>
                <input type="text"
                {...register("order", {required: true})}
                aria-invalid={errors.order ? "true" : "false"} />
                {errors.order && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Type</label>
                <small className="a-input__description" id="text-field-description">Selecteer het interactie-stijl van de vraag</small>
                <select {...register("type", {required: true})}>
                    <option value="SINGLE_CHOICE">Single choice</option>
                    <option value="MULTIPLE_CHOICE">Multiple choice</option>
                    <option value="SLIDER">Slider</option>
                    <option value="TEXT">Text</option>
                    <option value="AUTO_COMPLETE">Auto complete (many options)</option>
                    
                </select>
                {errors.style && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>
    );
};

export default QuestionDetails;