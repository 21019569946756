import React from 'react';
import { set, useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect, useState, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import signupService from '../../services/signup';
import pictureService from '../../services/picture';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth0 } from "@auth0/auth0-react";
import { Config } from "../../Config";
import { ImageInput } from '../ImageInput/ImageInput';


const ProviderDetails = ({onSubmit, onCancel, selectedProvider = {}, selectedCategory = {}}) => {
    const { register, handleSubmit, reset, formState: { errors, isDirty } } = useForm(
        {
            defaultValues: selectedProvider
        }
    );
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const placeInput = useRef(null);
    

    const [showFirstActivateTheAdminInThePractice, setShowFirstActivateTheAdminInThePractice] = useState(false);
    const [showAlreadyActivatedTheAdminInThePractice, setShowAlreadyActivatedTheAdminInThePractice] = useState(false);
    const [showAlreadyActivated, setShowAlreadyActivated] = useState(false);
    const [showFirstEnterEmail, setShowFirstEnterEmail] = useState(false);
    const [showActivate, setShowActivate] = useState(false);
    const [activating, setActivating] = useState(false);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [company, setCompany] = useState(''); // Initialize vatNr state
    const [vatNr, setVatNr] = useState(''); // Initialize vatNr state
    const [emailContent, setEmailContent] = useState(''); // Initialize emailContent state
    const [planCode, setPlanCode] = useState(''); // Initialize planCode state
    const [error, setError] = useState(''); // Initialize error state
    const customStylesheet = `
        /* Your custom CSS styles here */
        .ql-editor {
            font-family: Arial, sans-serif;
            font-size: 16px;
            color: #333;
            
        }
        h4 {
            margin-bottom:20px;
            font-size: 20px;
        }
    `;
    const apiKey = Config.getGoogleMapsApiKey();
   
    

    useEffect(() => {
        reset(selectedProvider);
    }, [selectedProvider]);


    useEffect(() => {

        setAddress(selectedProvider.address);
        setCity(selectedProvider.city);
        setPostalCode(selectedProvider.practiceRegionZipCode);
        setCoordinates({ lat: selectedProvider.lat, lng: selectedProvider.lng });   
        setProfilePictureUrl(selectedProvider.profilePictureUrl);   

        if (placeInput.current === null) {
          // Handle the case where the ref is null, or simply return to avoid proceeding
          return;
        }
        const loader = new Loader({
            apiKey: Config.getGoogleMapsApiKey(),
            libraries: ["places"]
        });
    
        loader
        .load()
        .then(() => {
            if (!placeInput.current) return;
            const autocomplete = new window.google.maps.places.Autocomplete(placeInput.current, {
            types: ['geocode'],
            componentRestrictions: { country: "BE" },
            });

            autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setCoordinates({ lat, lng });
                const street = place.address_components.find((component) => component.types.includes('route'))?.long_name || '';
                const streetNumber = place.address_components.find((component) => component.types.includes('street_number'))?.long_name || '';
                setCity(place.address_components.find((component) => component.types.includes('locality'))?.long_name || '');
                setPostalCode(place.address_components.find((component) => component.types.includes('postal_code'))?.long_name || '');
                setAddress(`${street} ${streetNumber} ${place.address_components.find((component) => component.types.includes('postal_code'))?.long_name || ''} ${place.address_components.find((component) => component.types.includes('locality'))?.long_name || ''}`);

            } else {
                console.log("Place has no geometry");
            }
            });

        })
        .catch((e) => {
            // do something
        });

    }, [selectedProvider]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent default behavior
          e.stopPropagation(); // Stop event propagation
          // Your custom logic here (optional)
        }
      };

    const save = (data) => {
        if(!address) {
            setError('Gelieve een adres in te geven!');
            return;
        }
        data.address = address;
        data.city = city;
        data.practiceRegionZipCode = postalCode;
        data.lat = coordinates.lat;
        data.lng = coordinates.lng;
        data.profilePictureUrl = profilePictureUrl;
        onSubmit(data);
     
    };


    function dataURLtoBlob(dataURL) {
        const parts = dataURL.split(",");
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uint8Array = new Uint8Array(rawLength);
        
        for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uint8Array], { type: contentType });
    }


    const doShowActivate = async (e) => {

        e.preventDefault();
        if(selectedProvider.activated){
            setShowAlreadyActivated(true);
            return;
        }
        if(selectedProvider.type !== "ADMIN" && !selectedCategory.providers.some(provider => provider.type === 'ADMIN' && provider.activated === true)){
            setShowFirstActivateTheAdminInThePractice(true);
            return;
        }
        if(selectedProvider.type === "ADMIN" && selectedCategory.providers.some(provider => provider.type === 'ADMIN' && provider.activated === true)){
            setShowAlreadyActivatedTheAdminInThePractice(true);
            return;
        }
        if(!selectedProvider.email){
            setShowFirstEnterEmail(true);
            return;
        }
        setEmailContent(`<body>
            <div>
                <p>Welkom {{firstName}}</p>
                <p>Wij zijn zeer blij u te mogen verwelkomen als nieuwe gebruiker bij Mia.</p>
                <p>Om uw account te activeren klikt u aub op de link hieronder. De link blijft 24 uur geldig. Als klikken op de link niet werkt kan u de link kopiëren naar het locatie veld in uw browser en enter drukken.</p>
                <p><a href="{{url}}">Klik hier om uw account te activeren</a></p>
                <p>of kopieer naar de browser:<br/>{{url}}</p>
                <br/>Met vriendelijke groeten<br />
                <p>Het Mia team</p>
            </div>
        </body>`);
        setPlanCode("MIAUNLIMITED");
        if(selectedProvider.type === "ADMIN"){
            setCompany(selectedCategory.name);
        }
        setShowActivate(true);

    };
    const modules = {
        toolbar: false, // Disable toolbar
    };

    const activate = async (e) => {

        e.preventDefault();
        if(selectedProvider.activated){
            setShowAlreadyActivated(true);
            return;
        }
        if(selectedProvider.type !== "ADMIN" && !selectedCategory.providers.some(provider => provider.type === 'ADMIN' && provider.activated === true)){
            setShowFirstActivateTheAdminInThePractice(true);
            return;
        }
        if(selectedProvider.type === "ADMIN" && selectedCategory.providers.some(provider => provider.type === 'ADMIN' && provider.activated === true)){
            setShowAlreadyActivatedTheAdminInThePractice(true);
            return;
        }
        if(!selectedProvider.email){
            setShowFirstEnterEmail(true);
            return;
        }

        if (!emailContent || !planCode) {
            setError('Gelieve de ontbrekende verplichte velden in te vullen!');
            return;
        }
        setError('');
        setActivating(true);
        try {
            var signup = {
                company: company,
                providerId: selectedProvider.id,
                email: selectedProvider.email,
                firstName: selectedProvider.name,
                lastName: selectedProvider.surName,
                city: selectedProvider.city,
                postalCode: selectedProvider.postalCode,
                address: selectedProvider.address,
                vatNr: vatNr,
                planCode: planCode,
                subscription: planCode,
                languageCode: "nl",
                emailContent: emailContent,
                countryId: -1  
            };

            if(selectedProvider.type !== "ADMIN"){
                if(selectedProvider.type === "INVOICEDSEPARATE"){
                    signup.sendMiaInvoiceToPractice = false;
                }
                else {
                    signup.sendMiaInvoiceToPractice = true;
                }
                signup.practiceId = selectedCategory.providers.find(provider => provider.type === 'ADMIN').practiceId;
                var result = await signupService.signupNormalUser(getAccessTokenSilently, getAccessTokenWithPopup, signup);
                selectedProvider.auth0Id = result.data;
            } else {
                var result = await signupService.signupPracticeUser(getAccessTokenSilently, getAccessTokenWithPopup, signup);
                selectedProvider.auth0Id = result.data.applicationUserId;
                selectedProvider.practiceId = result.data.practiceId;    
            }
            //close the panel now and save the provider
            selectedProvider.activated = true;
            onSubmit(selectedProvider);
            setActivating(false);
        } catch (error) {
            // Handle errors if any
            console.error('Error signing up user:', error);
            setActivating(false);
        }
        
    };

    return (
        <div style={{width:'100%', overflow: 'scroll'}}>
        {!showActivate && <div>
        {selectedProvider.name && 
            <span><h1>hulpverlener</h1><p>{selectedProvider.name} {selectedProvider.surName}</p><br/></span>
        }
        {!selectedProvider.name && 
            <span><h1>Nieuwe hulpverlener</h1><br/></span>
        }
        <form onSubmit={handleSubmit(save)} className="a-form" style={{width:'100%'}}>
            
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Voornaam</label>
                <small className="a-input__description" id="text-field-description">Geef de voornaam in</small>
                <input type="text"
                {...register("name", {required: true})}
                aria-invalid={errors.name ? "true" : "false"} />
                {errors.name && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Familienaam</label>
                <small className="a-input__description" id="text-field-description">Geef de familienaam in</small>
                <input type="text"
                {...register("surName", {required: true})}
                aria-invalid={errors.surName ? "true" : "false"} />
                {errors.surName && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">E-mail</label>
                <small className="a-input__description" id="text-field-description">Geef het email adres in</small>
                <input type="text"
                {...register("email", {required: false})}
                aria-invalid={errors.email ? "true" : "false"} />
                {errors.email && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
            <label className="a-input__label" htmlFor="text-field">Profielfoto</label>
            <small className="a-input__description" id="text-field-description">Upload een Profielfoto</small>
            <ImageInput
                    onChange={(file) => {
                     
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = (event) => {
                        const img = new Image();
                        if(event.target)
                        {
                          img.src = event.target?.result;

                          img.onload = () => {

                            const maxDimension = 800; // Set a maximum width or height, e.g., 800px
                            let width = img.width;
                            let height = img.height;

                            // Calculate the new dimensions while maintaining the aspect ratio
                            if (width > height) {
                                if (width > maxDimension) {
                                    height *= maxDimension / width;
                                    width = maxDimension;
                                }
                            } else {
                                if (height > maxDimension) {
                                    width *= maxDimension / height;
                                    height = maxDimension;
                                }
                            }

                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            canvas.width = width;
                            canvas.height = height;
                            if(ctx)
                            {
                              ctx.drawImage(img, 0, 0, width, height);

                              // Adjust the quality parameter to control the compression level
                              const compressedDataURL = canvas.toDataURL("image/jpeg", 0.5);

                              // Convert the data URL to a Blob
                              const compressedFile = dataURLtoBlob(compressedDataURL);

                              // Create a FormData object and append the compressed file
                              const formData = new FormData();
                              formData.append("binTypeImage", compressedFile, file.name);

                              // Send the compressed image to the server
                              pictureService.post(getAccessTokenSilently, getAccessTokenWithPopup, formData).then((response) =>
                                {setProfilePictureUrl(response.data.url);}
                              );
                            }

                          };
                        }
                      };
                    }}
                    imageUrl={profilePictureUrl}
                  />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Adres</label>
                <small className="a-input__description" id="text-field-description">Geef het adres in</small>
                <input ref={placeInput}
                          type="text" 
                          onKeyDown={handleKeyDown}
                          
                        />
                <br/>
                <br/>
                Het adres wordt opgeslagen als:
                <div className="chosen-address">{address}</div>
                      
            </div>

            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Voorstelling van therapeutische praktijk en aanpak</label>
                <small className="a-input__description" id="text-field-description">Geef een introductie tekst in</small>
                <textarea
                    {...register("practiceDescription", {required: true})}
                    aria-invalid={errors.practiceDescription ? "true" : "false"}
                    />
                {errors.practiceDescription && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>

            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Type</label>
                <small className="a-input__description" id="text-field-description">Selecteer het type hulpverlener</small>
                <select {...register("type", {required: true})}>
                    <option value="ADMIN">Administrator</option>
                    <option value="NORMAL">Normale medewerker</option>
                    <option value="INVOICEDSEPARATE">Appart gefactureerd</option>
                </select>
                {errors.type && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            {error && <div style={{ color: 'red' }}>{error}<br/><br/></div>}
            {isDirty && <div style={{ color: 'red' }}>Sla eerst de wijzigingen op om te kunnen activeren<br/><br/></div>}
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<Button className="a-button a-button--success" disabled={isDirty} onClick={(e) => doShowActivate(e)}>Activeer</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>}
        {showFirstActivateTheAdminInThePractice && 
            <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
                <div className="m-modal__content">
                <div className="m-modal__header u-margin-bottom-xs">
                    <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setShowFirstActivateTheAdminInThePractice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
                    <h4 id="myModalTitle" className="h6">Opgepast</h4>
                </div>
                <div className="u-margin-bottom">
                    <p id="myModalDesc">Activeer eerst een administrator in deze praktijk!</p>
                </div>
                <div className="m-modal__footer">
                   
                    <button className="a-button a-button--outlined" onClick={() => {setShowFirstActivateTheAdminInThePractice(false)}}>Sluiten</button>
                </div>
                </div>
            </div>
        }
        {showAlreadyActivatedTheAdminInThePractice && 
            <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
                <div className="m-modal__content">
                <div className="m-modal__header u-margin-bottom-xs">
                    <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setShowFirstActivateTheAdminInThePractice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
                    <h4 id="myModalTitle" className="h6">Opgepast</h4>
                </div>
                <div className="u-margin-bottom">
                    <p id="myModalDesc">Er werd al een administrator geactiveerd in deze praktijk!</p>
                </div>
                <div className="m-modal__footer">
                   
                    <button className="a-button a-button--outlined" onClick={() => {setShowAlreadyActivatedTheAdminInThePractice(false)}}>Sluiten</button>
                </div>
                </div>
            </div>
        }   
        {showAlreadyActivated && 
            <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
                <div className="m-modal__content">
                <div className="m-modal__header u-margin-bottom-xs">
                    <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setShowFirstActivateTheAdminInThePractice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
                    <h4 id="myModalTitle" className="h6">Opgepast</h4>
                </div>
                <div className="u-margin-bottom">
                    <p id="myModalDesc">Deze hulpverlener werd reeds geactiveerd!</p>
                </div>
                <div className="m-modal__footer">
                   
                    <button className="a-button a-button--outlined" onClick={() => {setShowAlreadyActivated(false)}}>Sluiten</button>
                </div>
                </div>
            </div>
        }   
        {showFirstEnterEmail && 
            <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
                <div className="m-modal__content">
                <div className="m-modal__header u-margin-bottom-xs">
                    <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setShowFirstActivateTheAdminInThePractice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
                    <h4 id="myModalTitle" className="h6">Opgepast</h4>
                </div>
                <div className="u-margin-bottom">
                    <p id="myModalDesc">Geef een email adres in!</p>
                </div>
                <div className="m-modal__footer">
                   
                    <button className="a-button a-button--outlined" onClick={() => {setShowFirstEnterEmail(false)}}>Sluiten</button>
                </div>
                </div>
            </div>
        }
        {showActivate &&
            <div>
              <h1>Activeer hulpverlener</h1><br/>
              <form onSubmit={activate} className="a-form" style={{width:'100%'}}>
              {selectedProvider.type !== "NORMAL" && <>
                <div className="a-input">
                    <label className="a-input__label" htmlFor="text-field">Bedrijf</label>
                    <small className="a-input__description" id="text-field-description">Geef het bedrijf in</small>
                    <input type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)} />
                </div>
                <div className="a-input">
                    <label className="a-input__label" htmlFor="text-field">BTW nummer</label>
                    <small className="a-input__description" id="text-field-description">Geef het btw nummer in</small>
                    <input type="text"
                    value={vatNr}
                    onChange={(e) => setVatNr(e.target.value)} />
                </div> </>}
                <div className="a-input">
                    <label className="a-input__label" htmlFor="text-field">Activatie email (verplicht)</label>
                    <small className="a-input__description" id="text-field-description">Geef de activatie email tekst in</small>
                    <ReactQuill
                theme="snow"
                    value={emailContent}
                    onChange={setEmailContent}
                    modules={modules}
                    styleSheet={customStylesheet}
                  ></ReactQuill>
                </div>
                {selectedProvider.type !== 'NORMAL' && <div className="a-input">
                    <label className="a-input__label" htmlFor="text-field">Formule (verplicht)</label>
                    <small className="a-input__description" id="text-field-description">Kies een formule</small>
                    <select
                        value={planCode}
                        onChange={(e) => setPlanCode(e.target.value)}
                    >
                        <option value="MIAUNLIMITED">Mia Standaard</option>
                        <option value="MIAPROFIEL">Mia Starter</option>
                    </select>
                </div>}
                
                {error && <div style={{ color: 'red' }}>{error}<br/><br/></div>}
                <button disabled={activating} className="a-button a-button--success" type="submit">Activeer</button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
              </form>
            </div>
        }

        </div>
    );
};

export default ProviderDetails;