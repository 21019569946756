import React from 'react';
import { useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect } from 'react';

const MethodDetails = ({onSubmit, onCancel, selectedMethod = {}}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm(
        {
            defaultValues: selectedMethod
        }
    );

    useEffect(() => {
        reset(selectedMethod);
    }, [selectedMethod])  

    return (
        <div style={{width:'100%', overflow:'scroll'}}>
        {selectedMethod.name && 
            <span><h1>Kenmerk</h1><p>{selectedMethod.name}</p><br/></span>
        }
        {!selectedMethod.name && 
            <span><h1>Nieuw kenmerk</h1><br/></span>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="a-form" style={{width:'100%'}}>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Code</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een code in voor identificatie</small>
                <input type="text"
                {...register("code")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">AI phrase</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een frasering voor AI</small>
                <input type="text"
                {...register("aiPhrase")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">AI Opposite phrase</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een niet-frasering voor AI</small>
                <input type="text"
                {...register("aiNoPhrase")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Naam</label>
                <small className="a-input__description" id="text-field-description">Geef het kenmerk in</small>
                <input type="text"
                {...register("name", {required: true})}
                aria-invalid={errors.text ? "true" : "false"} />
                {errors.text && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Uitleg over het kenmerk</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel meer uitleg over het kenmerk</small>
                <textarea
                    {...register("information")}
                    />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Order</label>
                <small className="a-input__description" id="text-field-description">Geef een cijfer voor de volgorde in</small>
                <input type="text"
                {...register("order")}/>
            </div>
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>
    );
};

export default MethodDetails;