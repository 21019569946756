import React from 'react';
import { useForm } from "react-hook-form";
import { Button, TextField } from '@acpaas-ui/react-components';
import { useEffect } from 'react';

const AnswerDetails = ({onSubmit, onCancel, selectedAnswer = {}}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm(
        {
            defaultValues: selectedAnswer
        }
    );

    useEffect(() => {
        reset(selectedAnswer);
    }, [selectedAnswer])  

    return (
        <div style={{width:'100%', overflow:'scroll'}}>
        {selectedAnswer.text && 
            <span><h1>Vraag</h1><p>{selectedAnswer.description}</p><br/></span>
        }
        {!selectedAnswer.text && 
            <span><h1>Nieuw antwoord</h1><br/></span>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="a-form" style={{width:'100%'}}>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Code</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een code in voor identificatie</small>
                <input type="text"
                {...register("code")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">AI phrase</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een frasering voor AI</small>
                <input type="text"
                {...register("aiPhrase")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">AI Opposite phrase</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel een niet-frasering voor AI</small>
                <input type="text"
                {...register("aiNoPhrase")} />
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Description</label>
                <small className="a-input__description" id="text-field-description">Geef het antwoord in</small>
                <input type="text"
                {...register("description", {required: true})}
                aria-invalid={errors.text ? "true" : "false"} />
                {errors.text && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Extra info in tussenscherm (hoogdringendheid)</label>
                <small className="a-input__description" id="text-field-description">Geef optioneel extra info over het antwoord</small>
                <textarea
                    {...register("extraInfo")}
                    />
            </div>
            <div className="a-input">
                <small className="a-input__description" id="stop-questionnaire-description">Vink dit aan als je na dit antwoord de vragenlijst wenst te stoppen</small>
                <div className="a-input__checkbox">
                    <input
                        type="checkbox"
                        id="stopQuestionnaire"
                        {...register("stopQuestionnaire")}
                    />
                    <label htmlFor="stopQuestionnaire">Stoppen na dit antwoord</label>
                </div>
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Uitleg bij stoppen na dit antwoord</label>
                <small className="a-input__description" id="text-field-description">Geef informatie in</small>
                <textarea
                {...register("stopQuestionnaireReason")}/>
            </div>
            <div className="a-input">
                <label className="a-input__label" htmlFor="text-field">Order</label>
                <small className="a-input__description" id="text-field-description">Geef een cijfer voor de volgorde in</small>
                <input type="text"
                {...register("order", {required: true})}
                aria-invalid={errors.order ? "true" : "false"} />
                {errors.order && <span className="u-text-secondary">Dit is een verplicht veld</span>}
            </div>
            <Button type="primary">Opslaan</Button>&nbsp;&nbsp;<button type="button" onClick={onCancel} className="a-button a-button--outlined">Annuleren</button>
        </form>
        </div>
    );
};

export default AnswerDetails;